export const orderHelper = {
    getProductStatusColor,
    getProductTextStatusBackgroundColor
};

function getProductStatusColor(status) {
    switch (status) {
        case "NOT_ORDERED_NOT_PAID":
            return "badge color-text-white status-background-1"
        case "NOT_ORDERED_PAID":
            return "badge color-text-white status-background-2"
        case "ORDERED_FROM_SUPPLIER":
            return "badge color-text-white status-background-3"
        case "RESERVED_IN_SUPPLIER":
            return "badge color-text-white status-background-4"
        case "PAID_IN_SUPPLIER":
            return "badge color-text-white status-background-5"
        case "REALIZED_FROM_WAREHOUSE":
            return "badge color-text-white status-background-6"
        case "PREPARED_TO_SHIP":
            return "badge color-text-white status-background-7"
        case "SENT_TO_US_FROM_WHOLESALER":
            return "badge color-text-white status-background-8"
        case "RETURN":
            return "badge color-text-white status-background-9"
        case "RETURN_ACCEPTED_AT_WAREHOUSE":
            return "badge color-text-white status-background-9"
        case "COMPLAINT":
            return "badge color-text-white status-background-10"
        case "TAKEN_BY_COURIER":
            return "badge color-text-white status-background-12"
        case "SENT_TO_CUSTOMER_FROM_WHOLESALER":
            return "badge color-text-white status-background-12"
        case "SENT_TO_CUSTOMER_FROM_OUR_WAREHOUSE":
            return "badge color-text-white status-background-13"
        case "WAITING_ROOM":
            return "badge color-text-white status-background-14"
        case "READY_TO_PICKUP":
            return "badge color-text-white status-background-15"
        case "READY_FOR_PICKUP":
            return "badge color-text-white status-background-15"
        case "RETURNED_TO_THE_CUSTOMER":
            return "badge color-text-white status-background-16"
        case "REALIZED":
            return "badge color-text-white status-background-17"
        case "CANCELLED":
            return "badge color-text-white status-background-18"
        case "COLLECTED_FROM_SENDER":
            return "badge color-text-white status-background-12"
        case "ADOPTED_AT_BRANCH":
            return "badge color-text-white status-background-12"
        case "ON_ROAD":
            return "badge color-text-white status-background-12"
        case "ADOPTED_AT_SORTING_CENTER":
            return "badge color-text-white status-background-12"
        case "OUT_FOR_DELIVERY":
            return "badge color-text-white status-background-12"
        default:
            return ""
    }
}

function getProductTextStatusBackgroundColor(status) {
    switch (status) {
        case "NOT_ORDERED_NOT_PAID":
            return "status-background-1"
        case "NOT_ORDERED_PAID":
            return "status-background-2"
        case "ORDERED_FROM_SUPPLIER":
            return "status-background-3"
        case "RESERVED_IN_SUPPLIER":
            return "status-background-4"
        case "PAID_IN_SUPPLIER":
            return "status-background-5"
        case "REALIZED_FROM_WAREHOUSE":
            return "status-background-6"
        case "PREPARED_TO_SHIP":
            return "status-background-7"
        case "SENT_TO_US_FROM_WHOLESALER":
            return "status-background-8"
        case "RETURN":
            return "status-background-9"
        case "RETURN_ACCEPTED_AT_WAREHOUSE":
            return "status-background-9"
        case "COMPLAINT":
            return "status-background-10"
        case "TAKEN_BY_COURIER":
            return "status-background-12"
        case "SENT_TO_CUSTOMER_FROM_WHOLESALER":
            return "status-background-12"
        case "SENT_TO_CUSTOMER_FROM_OUR_WAREHOUSE":
            return "status-background-13"
        case "WAITING_ROOM":
            return "status-background-14"
        case "READY_TO_PICKUP":
            return "status-background-15"
        case "READY_FOR_PICKUP":
            return "status-background-15"
        case "RETURNED_TO_THE_CUSTOMER":
            return "status-background-16"
        case "REALIZED":
            return "status-background-17"
        case "CANCELLED":
            return "status-background-18"
        case "COLLECTED_FROM_SENDER":
            return "status-background-12"
        case "ADOPTED_AT_BRANCH":
            return "status-background-12"
        case "ON_ROAD":
            return "status-background-12"
        case "ADOPTED_AT_SORTING_CENTER":
            return "status-background-12"
        case "OUT_FOR_DELIVERY":
            return "status-background-12"
        default:
            return ""
    }
}