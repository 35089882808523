<template>
  <b-dropdown ref="dropdown" class="btn btn-sm" :variant="$store.getters['theme/isThemeDark'] ? 'dark' : 'light'" size="sm" style="display: contents">
    <template v-slot:button-content>
      <i class="fa fa-user" />
      Dane zamawiającego
    </template>

    <template v-if="deliveryAddress()">
      <b-dropdown-item-button>{{ deliveryAddress().firstName }} {{ deliveryAddress().lastName }}</b-dropdown-item-button>
      <b-dropdown-item-button>{{ deliveryAddress().street }}</b-dropdown-item-button>
      <b-dropdown-item-button>{{ deliveryAddress().postCode }} {{ deliveryAddress().city }}</b-dropdown-item-button>
    </template>
  </b-dropdown>
</template>
<script>

export default {

  props: {
    shopOrderId: {
      type: String,
      required: true
    },

    deliveryAddresses: {
      type: Map,
      required: true
    }
  },

  mounted() {
    this.$refs.dropdown.$el.classList.remove('btn-group');
    const observer = new MutationObserver(this.handleMutation);

    observer.observe(this.$refs.dropdown.$el, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: ['class'],
    });
  },

  methods: {
    handleMutation(mutationsList) {
      mutationsList.forEach((mutation) => {
        if (mutation.attributeName === 'class' && mutation.target.classList.contains('btn-group')) {
          this.$nextTick(() => {
            mutation.target.classList.remove('btn-group');
          });
        }
      });
    },

    deliveryAddress() {
      if (!this.deliveryAddresses) {
        return null
      }

      return this.deliveryAddresses.get(this.shopOrderId) || null
    }
  }

}
</script>